import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RouteEnum from '../../RouteEnum';
import { useRouteContext } from '../../RouteProvider';
import { Logger } from '../../services/Logger';
import type { ReduxState } from '../../store';
import { addPaymentMethod, loadBraintree } from '../../store/paymentMethods';
import { flashMessageError, flashMessageSuccess } from '../../store/session';
import CreditCardInput from '../Form/CreditCardInput';
import Loader from '../Loader';
import MetaTags from '../MetaTags';
import T from '../Translate';

export default function AddPaymentMethod() {
  const dispatch = useDispatch();
  const { clientToken } = useSelector((store: ReduxState) => ({
    clientToken: store.paymentMethods.clientToken,
  }));
  const { navigate, generatePath } = useRouteContext();
  const tokenize = useRef(null);

  const handleSubmit = async event => {
    event.preventDefault();
    let nonce: string;
    try {
      const tokenized = await tokenize.current();
      nonce = tokenized.nonce;
    } catch (error) {
      Logger.default.error(error, { clientToken, nonce });
      dispatch(flashMessageError('profile.paymentMethod.creditCard.errorText'));
      return;
    }
    try {
      await dispatch(addPaymentMethod(nonce));
      dispatch(flashMessageSuccess('profile.paymentMethod.flash.successfullyAdded'));
      navigate(generatePath(RouteEnum.MY_PROFILE));
    } catch (error) {
      Logger.default.error(error, { clientToken, nonce });
      dispatch(flashMessageError('profile.paymentMethod.creditCard.errorText'));
    }
  };

  useEffect(() => {
    dispatch(loadBraintree());
  }, []);

  return (
    <main className="main-container container--top">
      <MetaTags title="profile.paymentMethod.meta.title" />

      <form className="container container--sm pb-5" onSubmit={handleSubmit}>
        <h1 className="display-2 mb-5 text-center">
          <T id="global.creditCard" />
        </h1>

        <div className="font-size-lg text-muted text-center">
          <p>
            <T html id="profile.paymentMethod.creditCard.text" />
          </p>
        </div>

        {!clientToken ? (
          <Loader className="my-5 pt-5" />
        ) : (
          <>
            <div className="my-5 px-md-5">
              <CreditCardInput clientToken={clientToken} ref={tokenize} />
            </div>

            <div className="text-center mt-4 mb-5">
              <button className="btn btn-primary btn-lg px-5" type="submit">
                <T id="profile.paymentMethod.creditCard.add" />
              </button>
            </div>
          </>
        )}
      </form>
    </main>
  );
}
