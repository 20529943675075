import React, { type MutableRefObject, useState } from 'react';
import { Braintree, HostedField } from 'react-braintree-fields';

import type { BraintreeTokenizer } from '../../services/Braintree';
import { Sprite, SpriteName } from '../Sprite';
import T, { useTranslation } from '../Translate';

interface Props {
  clientToken: string;
}

function CreditCardInput({ clientToken }: Props, ref: MutableRefObject<BraintreeTokenizer>) {
  const [cardType, setCardType] = useState(null);
  const { t } = useTranslation();

  const onCardTypeChange = event => {
    if (!event || !event.cards || event.cards.length !== 1) {
      setCardType(null);
      return;
    }
    setCardType(event.cards[0].type.replace('-', ''));
  };

  const isVisa = cardType?.toLowerCase() === 'visa';

  return (
    <div className="credit-card-wrapper">
      <Braintree
        authorization={clientToken}
        className="credit-card credit-card-input"
        getTokenRef={t => {
          ref.current = t;
        }}
        onCardTypeChange={onCardTypeChange}
        styles={{
          input: { 'font-size': '16px', color: '#F22D2D' },
          '.valid': { color: '#495057' },
        }}
      >
        <div className="credit-card__head form-group">
          <div className="credit-card__head--float-box">
            {cardType !== null && <Sprite name={isVisa ? SpriteName.CARD_VISA : SpriteName.CARD_MASTERCARD} />}
          </div>
          <label htmlFor="credit-card-number">
            <T id="profile.paymentMethod.creditCard.cardNumber" />
          </label>
          <HostedField className="form-control" id="credit-card-number" type="number" />
        </div>
        <div className="credit-card__body">
          <div className="row">
            <div className="form-group col-6">
              <label htmlFor="credit-card-expiration">
                <T id="profile.paymentMethod.creditCard.validTo" />
              </label>
              <div className="form-row">
                <div className="col">
                  <HostedField
                    className="form-control"
                    id="credit-card-expiration"
                    placeholder="MM"
                    type="expirationMonth"
                  />
                </div>
                <div className="col">
                  <HostedField className="form-control" placeholder="YY" type="expirationYear" />
                </div>
              </div>
            </div>
            <div className="form-group col-3 offset-3">
              <label htmlFor="credit-card-cvv">
                <T id="profile.paymentMethod.creditCard.cvv" />
              </label>
              <HostedField
                className="form-control"
                id="credit-card-cvv"
                placeholder={t('profile.paymentMethod.creditCard.cvv')}
                type="cvv"
              />
            </div>
          </div>
        </div>
      </Braintree>
    </div>
  );
}

export default React.forwardRef(CreditCardInput);
